import * as React from "react";
import SectionHeader from "src/components/SectionHeader";
import VideosRow from "src/components/VideosRow";
import { Video } from "src/types";
import styles from "./recommendedVideos.module.css";

class RecommendedVideos extends React.Component<
  { videos: Video[]; hideDisableIcon?: boolean },
  any
> {
  public render() {
    return (
      <div className={styles.wrapper}>
        <SectionHeader header="Рекомендованные видео" />
        <VideosRow
          videos={this.props.videos}
          hideDisableIcon={this.props.hideDisableIcon}
        />
      </div>
    );
  }
}

export default RecommendedVideos;
