import * as React from "react";
import ReactItemsCarousel from "react-items-carousel";
import { Default } from "src/components/querysComponents";
import Video from "src/components/Video";
import left from "src/images/leftChevron.svg";
import right from "src/images/rightChevron.svg";
import { Video as VideoType } from "src/types";
import styles from "./videosRow.module.css";

interface Props {
  numberOfCards?: number;
  videos: VideoType[];
  hideDisableIcon?: boolean;
}

interface State {
  activeItemIndex: number;
}

class VideosRow extends React.Component<Props, State> {
  public state = {
    activeItemIndex: 0,
  };

  public render() {
    return (
      <div className={styles.carouselWrapper}>
        <Default mobile={this.renderMobile()}>{this.renderDefault()}</Default>
      </div>
    );
  }

  private mapVideos = () => {
    return this.props.videos.map((curr, i) => (
      <Video
        key={i}
        video={curr}
        hideDisableIcon={this.props.hideDisableIcon}
      />
    ));
  };

  private setActiveIndex = (value) => this.setState({ activeItemIndex: value });

  private getChevron = (node: string) => {
    return (
      <div className={styles.chevronWrapper}>
        <img src={node} alt="следующие видео" />
      </div>
    );
  };

  private getPlaceHolderItem = () => {
    return <div className={styles.placeholder} />;
  };

  private renderMobile() {
    return <div className={styles.mobileVideosWrapper}>{this.mapVideos()}</div>;
  }

  private renderDefault() {
    return (
      <ReactItemsCarousel
        enablePlaceholder
        placeholderItem={<this.getPlaceHolderItem />}
        numberOfPlaceholderItems={4}
        infiniteLoop
        gutter={24}
        chevronWidth={56}
        numberOfCards={4}
        slidesToScroll={2}
        outsideChevron
        rightChevron={this.getChevron(right)}
        leftChevron={this.getChevron(left)}
        activeItemIndex={this.state.activeItemIndex}
        requestToChangeActive={this.setActiveIndex}
      >
        {this.mapVideos()}
      </ReactItemsCarousel>
    );
  }
}

export default VideosRow;
